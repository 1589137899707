$(document).ready(function () {
  $(function cleanBody() {
    var body = $('body');

    if (body.is('.ajax-wait')) {
      body.removeClass('ajax-wait');
    } else {
      setTimeout(cleanBody, 500);
    }
  });
  function form_builder_email_format(value) {
	  var pattern = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,3})$/;

	  return pattern.test(value);
  }
  $('#site-email-signup, .site-email-signup__contents-close').bind('click', function () {
    $('.site-email-signup-v1').toggleClass('active');
  });

  var emailForm = $('#email_sms_signup'),
    error = $('#form--errors--email_sms_signup');

  error.hide();
  emailForm.bind('submit', function (e) {
    var emailAddress = $('#form--email_sms_signup--field--PC_EMAIL_ADDRESS').val();

    if (form_builder_email_format(emailAddress)) {
	  error.hide();

	  return true;
    } else {
	  error.show();

	  return false;
    }
  });
});
var dimOverlay = $('<div class="site-header__dim-overlay"></div>');
var siteContainer = $('.site-header').siblings('.site-container');

$('.site-email-signup__link').once().click(function (e) {
  dimOverlay.prependTo(siteContainer);
});
$('.site-email-signup__contents-close').once().click(function (e) {
  e.preventDefault();
  dimOverlay.remove();
});
$(window).resize(_.debounce(function () {
  if ($('.site-email-signup-v1').hasClass('active')) {
		  $('.site-email-signup-v1').removeClass('active');
    dimOverlay.remove();
  }
}, 50));
$(document).mouseup(function (e) {
  var popup = $('.site-email-signup__contents');

  if (!$('.site-email-signup__contents-close').is(e.target) && !popup.is(e.target) && popup.has(e.target).length == 0) {
    $('.site-email-signup-v1').removeClass('active');
    $('.site-header__dim-overlay').remove();
  }
});
